import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { InternalPolestarApp } from '@polestar/component-warehouse-react/internal';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router';
import {
  ApolloProvider,
  DatoBaseProvider,
  FeatureFlagProvider,
  I18nProvider,
  LocaleProvider,
  MarketsProvider,
  RolesProvider,
  TimezonesProvider
} from '~app/providers';
import pca from '~config/msal';

import routes from '~routes';

import DefaultLayout from '~app/layouts/DefaultLayout';
import NoHeaderLayout from '~app/layouts/NoHeaderLayout';

const router = createBrowserRouter(
  createRoutesFromElements(
    routes.map((route) => (
      <>
        {route.name !== 'customerConsent' ? (
          <Route path={route.path} element={<DefaultLayout>{route.view}</DefaultLayout>} />
        ) : (
          <Route path={route.path} element={<NoHeaderLayout>{route.view}</NoHeaderLayout>} />
        )}
      </>
    ))
  )
);

export const App = () => (
  <InternalPolestarApp style={{ width: '100%' }}>
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <LocaleProvider>
          <ApolloProvider>
            <DatoBaseProvider>
              <RolesProvider>
                <MarketsProvider>
                  <TimezonesProvider>
                    <I18nProvider>
                      <FeatureFlagProvider>
                        <CookiesProvider defaultSetOptions={{ path: '/' }}>
                          <RouterProvider router={router} />
                        </CookiesProvider>
                      </FeatureFlagProvider>
                    </I18nProvider>
                  </TimezonesProvider>
                </MarketsProvider>
              </RolesProvider>
            </DatoBaseProvider>
          </ApolloProvider>
        </LocaleProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </InternalPolestarApp>
);
