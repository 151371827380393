import React, { createContext } from 'react';

import moment from 'moment-timezone';

import 'moment/dist/locale/da';
import 'moment/dist/locale/de';
import 'moment/dist/locale/de-at';
import 'moment/dist/locale/de-ch';
import 'moment/dist/locale/en-au';
import 'moment/dist/locale/en-ca';
import 'moment/dist/locale/es';
import 'moment/dist/locale/es-us';
import 'moment/dist/locale/fi';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/fr-ca';
import 'moment/dist/locale/fr-ch';
import 'moment/dist/locale/it';
import 'moment/dist/locale/it-ch';
import 'moment/dist/locale/ko';
import 'moment/dist/locale/nb';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/pt';
import 'moment/dist/locale/sv';
import 'moment/dist/locale/zh-cn';

import append from 'ramda/src/append';

const FALLBACK_LOCALE = 'en-gb';

export const I18nContext = createContext([]);

const I18nProvider = ({ children }) => {
  const userLanguages = navigator.languages;
  const locales = append(FALLBACK_LOCALE, userLanguages);

  // Set moment.js current locale globablly.
  moment.locale(navigator.language);

  return <I18nContext.Provider value={locales}>{children}</I18nContext.Provider>;
};

export default I18nProvider;
